import React from "react";
import Page from "../components/Page";
import Space from "../components/elements/Space/Space";
import Layout from "../components/Layout";

const TerminiPage = () => (
  <Page title='Termini e Condizioni Piattaforma Assicurativa' description='' image='/favicon.png'>
    <Layout>
      {/* navigation space */}
      <Space mobile={15} table={15} desktop={15} />
      <section className="section ">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-md-10">
              <h2 style={{ textAlign: "center" }}> Termini e condizioni</h2>
              <ol>
                <li>
                  Il sito  https://app.e-leads.it/ (“<b>Sito</b>” o “<b>Piattaforma</b>”) è di proprietà e gestito da E-leads S.r.l. (“<b>Società</b>” “<b>E-leads</b>” o “<b>Gestore</b>”) una società a responsabilità limitata con sede legale in Strada Statale Trossi 41, Verrone (BI), partita iva IT02668420025, REA: BI-204055
                </li>
                <li>
                  Esso consiste in una Piattaforma di lead generation real-time che permette ad utenti interessati di segnalare i propri nominativi ad intermediari attivi nel campo assicurativo e compagnie di assicurazione (“<b>Partners</b>”). E-leads ha il mero ruolo di segnalatore limitandosi a fornire dati ed informazioni su potenziali assicurati a intermediari assicurativi e riassicurativi o a imprese di assicurazione, con esclusione di qualsiasi ulteriori iniziative di assistenza nella conclusione di un contratto assicurativo.
                </li>
                <li>
                  La Società non svolge le seguenti attività, le quali sono espressamente escluse:
                  <ul>
                    <li>assistenza o consulenza o altri atti preparatori relativi alla conclusione di contratti assicurativi; </li>
                    <li>assistenza e consulenza finalizzate alla stipulazione di contratti assicurativi; </li>
                    <li>stipulazione di contratti assicurativi; </li>
                    <li>collaborazione con l’assicurato o con l’assicuratore nella gestione o nell’esecuzione del contratto di assicurazione; </li>
                    <li>fornitura di informazioni tramite il sito internet relativamente a uno o più contratti di assicurazione con possibilità di concludere, direttamente o indirettamente, il relativo contratto. </li>
                  </ul>
                </li>
                <li>
                  E-leads non intende orientare l’utente nella scelta del prodotto assicurativo più confacente ai suoi profili di rischio. E-leads non ha ricevuto alcun incarico da parte di compagnie assicurative o intermediari ed opera in maniera assolutamente indipendente e priva di vincoli con intermediari, broker o compagnie assicurative, in particolare senza alcun vincolo di subordinazione, mandato, rappresentanza, agendo in maniera del tutto occasionale ed esclusivamente su sua iniziativa.<br />
                  L’utente è pertanto consapevole ed accetta che E-leads non potrà assisterlo nella conclusione o nell’esecuzione di alcun contratto di assicurazione né fornirà alcun apporto consulenziale o informazioni finalizzate al collocamento di uno o più prodotti assicurativi.

                </li>
                <li>
                  E-leads opera nell’ambito dei servizi di informazione e comunicazione ed esclude qualsiasi responsabilità per fatti non strettamente attinenti alla mera segnalazione dei nominativi e alla comunicazione dei dati conferiti ai Partners. L’attività di E-leads pertanto termina con la segnalazione dell’utente al Partner, con esclusione di qualsiasi coinvolgimento nella eventuale fase di conclusione del contratto.
                </li>
                <li>
                  E-leads non potrà garantire che la compilazione dei moduli comporti automaticamente la trasmissione della richiesta ai Partners, in particolare in caso di errori o malfunzionamenti non dipendenti dalla sua volontà, ne potrà garantire che i Partners che riceveranno i dati ricontatteranno l’utente fornendo loro le informazioni sollecitate.
                </li>
                <li>
                  Il Gestore si riserva di modificare, sospendere o interrompere l’utilizzo del Sito (o parte di esso) in qualsiasi momento e senza preavviso. Il Gestore non sarà responsabile nei confronti dell’utente in alcun modo se il Sito (o parte di esso) non è disponibile in qualsiasi momento e per qualsiasi periodo.
                </li>
                <li>
                  Tutto il contenuto pubblicato nel Sito è soggetto a copyright ed i relativi diritti appartengono alla Società. Tutti i contenuti sono protetti dalle leggi a tutela del diritto d’autore. L’utente non può riprodurre, copiare, distribuire, vendere, concedere in licenza, archiviare o in alcun altro modo riutilizzare il contenuto dal nostro sito se non previa espressa autorizzazione scritta concessa dalla Società.
                </li>
                <li>
                  La Società si riserva di modificare i presenti termini d’uso in qualsiasi momento. Si invitano gli utenti a prenderne visione con frequenza. La data di ultimo aggiornamento sarà aggiornata in calce.
                </li>
              </ol>

            </div>
          </div>

          <div className="row  justify-content-center">
            <div className="col-md-10 text-right">
              <i>Data ultima modifica [26 Luglio 2018]</i>
            </div>
          </div>


        </div>
      </section>
    </Layout>
  </Page>
);

export default TerminiPage;
